import { defineStore } from 'pinia';

export const useFooterStore = defineStore('footerStore', {
  state: () => ({
    footer: '',
    company_logo: '',
    company_name: '',
  }),
  actions: {
    setFooterData(footer, logo, company_name) {
      this.footer = footer;
      this.logo = logo;
      this.company_name = company_name;
    }
  },
});
