import { useAuthStore, useAuthFakeStore } from "@/state/pinia";
export default [
  {
    path: "/",
    name: "default",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/dashboards/default")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/account/admin-login"),
    meta: {
      title: "Admin",
      // beforeResolve(routeTo, routeFrom, next) {
      //   // If the user is already logged in
      //   if (localStorage.getItem('user')) {
      //     // Redirect to the home page instead
      //     next({ name: "dashboard" });
      //   } else {
      //     // Continue to the login page
      //     next({ name: "admin" });
      //   }
      // },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: "Forgot password",
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/reset-password",
    name: "Reset password",
    component: () => import("../views/account/new-password"),
    meta: {
      title: "Reset password",
      // beforeResolve(routeTo, routeFrom, next) {
      //   const auth = useAuthStore();
      //   // If the user is already logged in
      //   // if (store.getters["auth/loggedIn"]) {
      //   if (auth.loggedIn) {
      //     // Redirect to the home page instead
      //     next({ name: "default" });
      //   } else {
      //     // Continue to the login page
      //     next();
      //   }
      // }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // const auth = useAuthStore();
        const authFake = useAuthFakeStore();


        authFake.logout();

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      }
    }
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },
  {
    path: "/pages/maintenance",
    name: "Maintenance",
    meta: { title: "Maintenance Page", authRequired: true },
    component: () => import("../views/utility/maintenance")
  },
  {
    path: "/pages/coming-soon",
    name: "coming-soon",
    meta: { title: "Coming Soon", authRequired: true },
    component: () => import("../views/utility/coming-soon")
  },

  // customer pages
  {
    path: "/dashboard",
    name: "dashboard",
    meta: { title: "Dashboard", authRequired: true },
    component: () => import("../views/admin/dashboard"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // Security 
  {
    path: "/blocked-ips",
    name: "ips",
    meta: { title: "Blocked IPs", authRequired: true },
    component: () => import("../views/admin/ip/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  //customers
  {
    path: "/customers",
    name: "customers",
    meta: { title: "Customers", authRequired: true },
    component: () => import("../views/admin/customers/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/customer-invoices",
    name: "customer-invoices",
    meta: { title: "Customer Invoice", authRequired: true },
    component: () => import("../views/admin/customers/invoices.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/customer-invoice-details",
    name: "customer-invoice-details",
    meta: { title: "Invoice Details", authRequired: true },
    component: () => import("../views/admin/customers/invoice-details.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  //admins
  {
    path: "/admins",
    name: "admins",
    meta: { title: "Admins", authRequired: true },
    component: () => import("../views/admin/admins/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  //provider
  {
    path: "/providers",
    name: "providers",
    meta: { title: "Providers", authRequired: true },
    component: () => import("../views/admin/providers/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/provider-details",
    name: "providers-edit",
    meta: { title: "Providers", authRequired: true },
    component: () => import("../views/admin/providers/edit"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // optimize mysql
  {
    path: "/optimize-mysql",
    name: "optimize-mysql",
    meta: { title: "Optimize MySQL", authRequired: true },
    component: () => import("../views/admin/optimize-mysql/create"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // rewrite rules
  {
    path: "/rewrite-rules",
    name: "rewrite-rules",
    meta: { title: "Rewrite Rules", authRequired: true },
    component: () => import("../views/admin/rewrite-rules/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // software stacks

  {
    path: "/software-stacks",
    name: "stacks",
    meta: { title: "Software Stacks", authRequired: true },
    component: () => import("../views/admin/software-stack/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/software-stack-settings",
    name: "software-stack-settings",
    meta: { title: "Software Stack settings", authRequired: true },
    component: () => import("../views/admin/software-stack/settings"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // API Integration 
  {
    path: "/api-integration",
    name: "api-integration",
    meta: { title: "API Integration", authRequired: true },
    component: () => import("../views/admin/api-integration/index.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
    
  },

  // Admin profile 
  {
    path: "/admin-profile",
    name: "admin-profile",
    meta: { title: "Admin Profile", authRequired: true },
    component: () => import("../views/admin/profile/index.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
    
  },


  // project 

  {
    path: "/projects",
    name: "projects",
    meta: { title: "Projects", authRequired: true },
    component: () => import("../views/customer/projects/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/project-manager",
    name: "project-manager",
    meta: { title: "Project Manager", authRequired: true },
    component: () => import("../views/customer/projects/details.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // servers pages

  {
    path: "/servers",
    name: "servers",
    meta: { title: "Servers", authRequired: true },
    component: () => import("../views/customer/servers/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2 ) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/create-server",
    name: "create-server",
    meta: { title: "Create Server", authRequired: true },
    component: () => import("../views/customer/servers/create-server"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/create-order",
    name: "create-order",
    meta: { title: "Create Server", authRequired: true },
    component: () => import("../views/admin/orders/create-order"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  // Applications 
  {
    path: "/applications",
    name: "applications",
    meta: { title: "Applications", authRequired: true },
    component: () => import("../views/customer/applications/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/backups",
    name: "backups",
    meta: { title: "Manage Backups", authRequired: true },
    component: () => import("../views/customer/backup-manager/backups"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/storage-manager",
    name: "storagemanager",
    meta: { title: "Storage Manager", authRequired: true },
    component: () => import("../views/customer/backup-manager/storage-manager"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/manage-s3keys",
    name: "manages3keys",
    meta: { title: "Manage S3 Keys", authRequired: true },
    component: () => import("../views/customer/backup-manager/manage-s3-keys"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },


  {
    path: "/application-manager",
    name: "application-manager",
    meta: { title: "Application Manager", authRequired: true },
    component: () => import("../views/customer/applications/manager"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/create-application",
    name: "create-application",
    meta: { title: "Application", authRequired: true },
    component: () => import("../views/customer/applications/add"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/server-manager",
    name: "server-manager",
    meta: { title: "Servers", authRequired: true },
    component: () => import("../views/customer/servers/single/instance-data"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  //profile
  {
    path: "/profile",
    name: "profile",
    meta: { title: "Profile", authRequired: true },
    component: () => import("../views/customer/profile/profile"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  //notifications
  {
    path: "/notifications",
    name: "notifications",
    meta: { title: "Notifications", authRequired: true },
    component: () => import("../views/customer/notifications/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/support",
    name: "support",
    meta: { title: "Support", authRequired: true },
    component: () => import("../views/customer/support/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/open-ticket",
    name: "open-ticket",
    meta: { title: "Support", authRequired: true },
    component: () => import("../views/customer/support/open-ticket"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

   {
    path: "/server-health",
    name: "server-health",
    meta: { title: "Server Health", authRequired: true },
    component: () => import("../views/admin/server-health/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },


  {
    path: "/view-ticket",
    name: "view-ticket",
    meta: { title: "Support", authRequired: true },
    component: () => import("../views/customer/support/view-ticket"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },


  // Subscriptions 
  {
    path: "/subscriptions-pack",
    name: "subscriptions-pack",
    meta: { title: "Subscriptions", authRequired: true },
    component: () => import("../views/admin/subscriptions/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/create-subscription",
    name: "create-subscriptions",
    meta: { title: "Create Subscription", authRequired: true },
    component: () => import("../views/admin/subscriptions/add"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/edit-subscription",
    name: "edit-subscriptions",
    meta: { title: "Edit Subscription", authRequired: true },
    component: () => import("../views/admin/subscriptions/edit"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/email-verification",
    name: "email-verification",
    meta: { title: "Email Verification", authRequired: false },
    component: () => import("../views/account/email-verification"),
  },

  // configuration

  {
    path: "/configurations",
    name: "configurations",
    meta: { title: "Configurations", authRequired: true },
    component: () => import("../views/customer/configurations/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/orders",
    name: "orders",
    meta: { title: "Orders", authRequired: true },
    component: () => import("../views/admin/orders/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/order-invoice",
    name: "order-invoice",
    meta: { title: "Order Invoice", authRequired: true },
    component: () => import("../views/admin/orders/invoice"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/order-edit",
    name: "order-edit",
    meta: { title: "Order Edit", authRequired: true },
    component: () => import("../views/admin/orders/edit-order.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: { title: "Subscriptions", authRequired: true },
    component: () => import("../views/customer/subscriptions/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/invoices",
    name: "invoices",
    meta: { title: "Invoices", authRequired: true },
    component: () => import("../views/customer/invoices/invoice"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/invoices-list",
    name: "invoices-list",
    meta: { title: "Invoices", authRequired: true },
    component: () => import("../views/customer/invoices/list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/invoice-details",
    name: "invoice-details",
    meta: { title: "Invoice Details", authRequired: true },
    component: () => import("../views/customer/invoices/invoice-details"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

  {
    path: "/settings",
    name: "settings",
    meta: { title: "Settings", authRequired: true },
    component: () => import("../views/admin/settings/index"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/payment-gateways",
    name: "payment-gateways",
    meta: { title: "Payment Gateway", authRequired: true },
    component: () => import("../views/admin/configurations/payment-gateways.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is admin
      if (userRole.role == 1 || userRole.role == 3) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },


  {
    path: "/list-users",
    name: "list-users",
    meta: { title: "List Users", authRequired: true },
    component: () => import("../views/admin/staff-management/staff-list"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/staff-create",
    name: "staff-create",
    meta: { title: "Staff Management", authRequired: true },
    component: () => import("../views/admin/staff-management/create-staff"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/staff-edit",
    name: "staff-edit",
    meta: { title: "Staff Management", authRequired: true },
    component: () => import("../views/admin/staff-management/edit-staff"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/list-roles",
    name: "list-roles",
    meta: { title: "Roles", authRequired: true },
    component: () => import("../views/admin/staff-management/list-roles"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/create-role",
    name: "create-role",
    meta: { title: "Staff Management", authRequired: true },
    component: () => import("../views/admin/staff-management/create-role"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/edit-role",
    name: "edit-role",
    meta: { title: "Staff Management", authRequired: true },
    component: () => import("../views/admin/staff-management/create-role"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 1) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },
  {
    path: "/services",
    name: "services",
    meta: { title: "Services", authRequired: true },
    component: () => import("../views/customer/services/list.vue"),
    beforeEnter: (to, from, next) => {
      // Check the user's role
      const userRole = JSON.parse(localStorage.getItem('user')); // Get user details

      // Allow access only if it is customer
      if (userRole.role == 2) {
        next();
      } else {
        // Redirect to a different route or show an error message
        next({ name: 'Error-404' }); // Redirect to the 404 page
      }
    },
  },

];
